<template>
  <div class="hello">
		<section id="four" class="wrapper style2 special">
			<div class="inner">
				<header class="major narrow" data-aos="fade-down">
					<h2>Get in touch</h2>
				</header>

				<div class="container 75%" data-aos="fade-down" data-aos-delay="50">
					<div class="row uniform 50%">
						<div class="6u 12u$(xsmall)">
							<input v-model="name" name="name" placeholder="Name" type="text" />
						</div>
						<div class="6u$ 12u$(xsmall)">
							<input v-model="email" name="email" placeholder="Email" type="email" />
						</div>
						<div class="12u$">
							<textarea v-model="description" name="message" placeholder="Message" rows="4"></textarea>
						</div>
					</div>
				</div>
				<div v-if="sent" style="margin: 60px auto">
					<p>Thank you for your message, we will be in touch soon</p>
				</div>
				<div v-if="!sent" style="margin: 60px auto">
					<ul v-if="!sending" class="actions" >
						<li data-aos="fade-down" data-aos-offset="-100"><input type="button" class="special" value="Submit" v-on:click="submitForm" /></li>
						<li data-aos="fade-down" data-aos-offset="-100" data-aos-delay="50"><input type="button" class="alt" value="Reset" v-on:click="resetForm" /></li>
					</ul>
					<div v-if="sending">
						<Spinner />
					</div>
				</div>
			</div>
		</section>
  </div>
</template>

<script>

  import axios from 'axios'

  import Spinner from '@/components/Spinner.vue'

  export default {
	name: 'GetInTouch',
	components: {
		Spinner
	},
	data() {
		return {
			sent: false,
			sending: false,
			messageText: '',
			name: '',
			email: '',
			message: ''
		}
	},
	methods: {
		resetForm() {
			this.email = '';
			this.name = '';
			this.message = '';
		},
		submitForm() {

			function validateEmail(email) {
				const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(String(email).toLowerCase());
			}

			console.log("sending");
			console.log(this.name);
			console.log(this.email);
			console.log(this.message);

			const email = this.email;
			const name = this.name;
			const message = this.message;

			if (!email) {
				console.log("no email");
				toastr.error('Email cannot be empty'); 
				return;
			}

			if (!validateEmail(email)) {
				console.log("invalid email");
				toastr.error('Email invalid'); 
				return;
			}

			this.sending = true;

			axios.post('https://6w29j07xy3.execute-api.eu-west-2.amazonaws.com/default/contact', {
				name: name,
				email: email,
				message: message
			})
			.then((response) => {
				this.sending = false;
				this.sent = true;
				console.log("axios success", response);

				toastr.success('Message sent');
			})
			.catch((error) => {
				this.sending = false;
				console.log("axios fail", error);

				toastr.error('Ooops, something went wrong. Please try again later'); 
			});
		}
	}
  }
</script>
