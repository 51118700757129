<template>
	<div id="nav" class="std-pad">


			<router-link to="/about" v-bind:class="{ active: menu == '/about' }">
				ABOUT
			</router-link>
			<router-link to="/portfolio" v-bind:class="{ active: menu == '/portfolio' }">
				PORTFOLIO
			</router-link>
			<router-link class="serviceshover" to="/services" v-bind:class="{ active: menu == '/services' }">
				SERVICES
			</router-link>

	</div>
</template>

<script>

	export default {
		name: 'Header',
		data() {
			return {
				menu: 'home'
			}
		},
		watch:{
		    $route (to, from) {
				const pathname = window.location.pathname;
				this.menu = pathname.split('#')[0];
		    }
		},
		computed: {
			route() {
				const pathname = window.location.pathname;
				return pathname.split('#')[0];
			}
		},
		mounted() {

			const pathname = window.location.pathname;
			return pathname.split('#')[0];

		}
	}

</script>

<style scoped>
.servicesscontainer {
  display: none;
  top: 60px;
  position: absolute;
  right: 128px;
  padding: 40px;
  background-color: #000a;
}
.servicesscontainer .service {
  padding-bottom: 10px;
}
.serviceshover:hover + .servicesscontainer, .servicesscontainer:hover {
  display: block;
}
#nav {
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0;
  position: fixed;
  background-color: #000;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
}
#nav h2 {
  margin: 4px 20px;
  display: inline-block;
  float: left;
}
#nav img {
  width: 60px;
  float: left;
}
#nav a {
  font-weight: bold;
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
}
#nav .links-container {
  float: right;
  margin-top: 12px;
}
@media (max-width: 800px) {
  #nav h2 {
    font-size: 20px;
  }
  #nav img {
    width: 46px;
  }
  #nav a {
    font-size: 12px;
  }
  #nav .links-container {
    margin-top: 2px;
    margin-right: 20px;
  }
}
@media (max-width: 600px) {
  #nav h2 {
    font-size: 16px;
  }
  #nav img {
    width: 46px;
  }
  #nav a {
    font-size: 10px;
  }
  #nav .links-container {
    margin-top: 0;
    margin-right: 0;
  }
}
/* hamburger menu */
#menuToggle {
  display: block;
  position: relative;
  left: 50px;
  padding-right: 40px;
  top: 10px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}
#menuToggle a {
  text-decoration: none;
  color: #232323;
  transition: color 0.3s ease;
}
#menuToggle a:hover {
  color: #0071bc;
}
#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  /* hide this */
  z-index: 2;
  /* and place it over the hamburger */
  -webkit-touch-callout: none;
}
/* Just a quick hamburger */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
#menuToggle span:first-child {
  transform-origin: 0% 0%;
}
#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
/* Transform all the slices of hamburger into a crossmark */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}
/* But let's hide the middle one */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
/* the last one should go the other direction */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}
/* Make this absolute positioned at the top right of the screen */
#menu {
  position: absolute;
  min-height: 300px;
  right: -80px;
  width: 340px;
  margin: -100px 50px 0 0;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}
#menu li {
  padding: 10px 0;
  font-size: 22px;
}
/* Let's slide it in from the left */
#menuToggle input:checked ~ ul {
  transform: none;
}
.desktop-menu router-link:hover {
  color: #aaa;
}
@media (min-width: 1000px) {
  .mobile-menu {
    display: none;
  }
  .desktop-menu {
    display: block;
  }
}
@media (max-width: 1000px) {
  #menu {
    right: -120px !important;
  }
  #menuToggle {
    left: 10px;
    padding-right: 20px;
  }
  .mobile-menu {
    display: block;
  }
  .desktop-menu {
    display: none;
  }
}


</style>
