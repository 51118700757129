<template>
  <div class="home">

		<!-- Banner -->
		<section class="banner home-banner">
			<h2 style="margin-top: 100px; margin-bottom: 80px; color: #fff">Hindsight <span class="dark-blue">Homes</span></h2>
			<ul class="actions">
				<li><router-link to="/about" class="button big special">Learn More</router-link></li>
			</ul>
		</section>

		<!-- Zero -->
		<section id="zero" class="wrapper special">
			<div class="description-container">
				<p data-aos="fade-down" class="deep-blue">We are a lettings agency based in Ipswich, Suffolk. All our properties are well maintained with modern fittings and installations and come with all the relevant certifications (gas, electric, etc.). We provide family homes that are accessible both in terms of location and affordablility! We love animals so all our homes are pet friendly.</p>
			</div>
		</section>

		<!-- One -->
		<section id="one" class="wrapper style1">

			<div class="inner">
				<article class="feature left" data-aos="fade-down">
					<span class="image">
                        <picture>
                            <source srcset="@/assets/pic01.webp" type="image/webp">
                            <source srcset="@/assets/pic01.jpg" type="image/jpeg"> 
                            <img src="@/assets/pic01.jpg" alt="Modern House Design" />
                        </picture>
					</span>
					<div class="content">
						<h2>PROPERTY MANAGERS YOU CAN TRUST</h2>
						<p>We bring a thorough, rigorous approach to estate management</p>
						<ul class="actions">
							<li>
								<router-link to="/about" class="button alt">More</router-link>
							</li>
						</ul>
					</div>
				</article>
				<article class="feature right" data-aos="fade-down">
					<span class="image">
                        <picture>
                            <source srcset="@/assets/pic02.webp" type="image/webp">
                            <source srcset="@/assets/pic02.jpg" type="image/jpeg"> 
                            <img src="@/assets/pic02.jpg" alt="Classic House Design" />
                        </picture>
					</span>
					<div class="content">
						<h2>A PROPERTY MANAGEMENT COMPANY THAT CARES</h2>
						<p>We manage your home as though we live there</p>
						<ul class="actions">
							<li>
								<router-link to="/about" class="button alt">More</router-link>
							</li>
						</ul>
					</div>
				</article>
				<article class="feature left" data-aos="fade-down">
					<span class="image">
                        <picture>
                            <source srcset="@/assets/keys.webp" type="image/webp">
                            <source srcset="@/assets/keys.jpg" type="image/jpeg"> 
                            <img src="@/assets/keys.jpg" alt="House Keys" />
                        </picture>
					</span>
					<div class="content">
						<h2>GOOD WITH MANAGEMENT MAINTENANCE &amp; FINANCE</h2>
						<p>We never forget we are managing your money on your behalf</p>
						<ul class="actions">
							<li>
								<router-link to="/about" class="button alt">More</router-link>
							</li>
						</ul>
					</div>
				</article>
			</div>
		</section>

		<!-- Two -->
		<!--
		<section id="two" class="wrapper special" style="display:none;">
			<div class="inner">
				<header class="major narrow">
					<h2>Gallary</h2>
					<p>Ipsum dolor tempus commodo turpis adipiscing Tempor placerat sed amet accumsan</p>
				</header>
				<div class="image-grid">
					<a class="image"><img src="@/assets/pic03.jpg" alt="" /></a>
					<a class="image"><img src="@/assets/pic04.jpg" alt="" /></a>
					<a class="image"><img src="@/assets/pic09.jpg" alt="" /></a>
					<a class="image"><img src="@/assets/pic08.jpg" alt="" /></a>
					<a class="image"><img src="@/assets/pic05.jpg" alt="" /></a>
					<a class="image"><img src="@/assets/pic06.jpg" alt="" /></a>
					<a class="image"><img src="@/assets/pic07.jpg" alt="" /></a>
					<a class="image"><img src="@/assets/pic10.jpg" alt="" /></a>
				</div>
				<ul class="actions">
					<li><a href="#" class="button big alt">View More</a></li>
				</ul>
			</div>
		</section>
		-->

		<!-- Three -->
		<section id="three" class="wrapper style3 special">
			<div class="inner">
				<header class="major narrow	">
					<h2>Serving Ipswich and the surrounding areas</h2>
					<!--<p>Ipsum dolor tempus commodo turpis adipiscing Tempor placerat sed amet accumsan</p>-->
				</header>
				<!--
				<ul class="actions">
					<li><a href="#" class="button big alt">Magna feugiat</a></li>
				</ul>
				-->
			</div>
		</section>

		<!-- Four -->
    <GetInTouch />

  </div>
</template>

<script>
// @ is an alias to /src
import GetInTouch from '@/components/GetInTouch.vue'

export default {
	name: 'Home',
	components: {
		GetInTouch
	},
	mounted() {
		AOS.init();
		window.addEventListener('load', AOS.refresh)
	}
}
</script>
