<template>
	<div>
		<div id="header">
			<p class="logo-link">
				<router-link to="/"><span class="light-blue">HINDSIGHT</span> <span class="deep-blue">HOMES</span></router-link>
			</p>
			<div class="link-container">
				<router-link to="/">Home</router-link>
				<router-link to="/about">About</router-link>
				<router-link to="/contact">Contact</router-link>
			</div>
		</div>

		<!--<Header></Header>-->

		<router-view/>

		<!-- Footer -->
		<footer id="footer">
			<div class="inner">
				<ul class="icons" style="display: none;">
					<li><a href="#" class="icon fa-facebook">
						<span class="label">Facebook</span>
					</a></li>
					<li><a href="#" class="icon fa-twitter">
						<span class="label">Twitter</span>
					</a></li>
					<li><a href="#" class="icon fa-instagram">
						<span class="label">Instagram</span>
					</a></li>
					<li><a href="#" class="icon fa-linkedin">
						<span class="label">LinkedIn</span>
					</a></li>
				</ul>
				<ul class="copyright">
					<li>&copy; Hindsight Homes Ltd. 2023</li>
					<li>Design by <a href="https://kasper.design" target="_blank">kasper.design</a>.</li>
				</ul>
			</div>
		</footer>
	</div>
</template>

<script>

	import Header from '@/components/Header.vue'

	export default {
		name: 'App',
		components: {
			Header
		},
		watch:{
			$route (to, from) {
				console.log("route changed");
				setTimeout(() => {
					$('html,body').scrollTop(0);
					AOS.init();
				}, 100)
			}
		},
		mounted() {
			AOS.init();
		}
	}
</script>

<style>
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,700");
* {
  scroll-behavior: smooth;
}
#app {
  font-family: Comforta, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#header a {
  color: #415A77;
}
.logo-link {
  text-align: left;
  padding: 0 20px;
}
.link-container {
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding-right: 40px;
}
@media screen and (max-width: 600px) {
  #header a {
    font-size: 0.75em;
  }
  .link-container {
    padding-right: 10px;
  }
}
h1, h2, h3 {
  font-family: ComfortaBold;
}
/*@import url(font-awesome.min.css);*/
/* Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  -webkit-text-size-adjust: none;
}
.description-container {
  margin: 0 auto 20px;
  padding: 0 40px;
  width: 100%;
  max-width: 1200px;
}
@media screen and (max-width: 600px) {
  .description-container {
    padding: 20px;
  }
}
/* Colors */
.deep-blue {
  color: #0D1B2A !important;
}
.dark-blue {
  color: #1B263B !important;
}
.blue {
  color: #415A77 !important;
}
.light-blue {
  color: #778DA9 !important;
}
.cream {
  color: #E0E1DD !important;
}
.red {
  color: #ED3F54 !important;
}
.router-link-active {
  color: #ed3f54 !important;
}
/* Box Model */
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
/* Containers */
.container {
  margin-left: auto;
  margin-right: auto;
}
.container.\3125\25 {
  width: 100%;
  max-width: 100em;
  min-width: 80em;
}
.container.\375\25 {
  width: 60em;
}
.container.\350\25 {
  width: 40em;
}
.container.\325\25 {
  width: 20em;
}
.container {
  width: 80em;
}
@media screen and (max-width: 1680px) {
  .container.\3125\25 {
    width: 100%;
    max-width: 100em;
    min-width: 80em;
  }
  .container.\375\25 {
    width: 60em;
  }
  .container.\350\25 {
    width: 40em;
  }
  .container.\325\25 {
    width: 20em;
  }
  .container {
    width: 80em;
  }
}
@media screen and (max-width: 1280px) {
  .container.\3125\25 {
    width: 100%;
    max-width: 81.25em;
    min-width: 65em;
  }
  .container.\375\25 {
    width: 48.75em;
  }
  .container.\350\25 {
    width: 32.5em;
  }
  .container.\325\25 {
    width: 16.25em;
  }
  .container {
    width: 65em;
  }
}
@media screen and (max-width: 980px) {
  .container.\3125\25 {
    width: 100%;
    max-width: 112.5%;
    min-width: 90%;
  }
  .container.\375\25 {
    width: 67.5%;
  }
  .container.\350\25 {
    width: 45%;
  }
  .container.\325\25 {
    width: 22.5%;
  }
  .container {
    width: 90% !important;
  }
}
@media screen and (max-width: 736px) {
  .container.\3125\25 {
    width: 100%;
    max-width: 112.5%;
    min-width: 90%;
  }
  .container.\375\25 {
    width: 67.5%;
  }
  .container.\350\25 {
    width: 45%;
  }
  .container.\325\25 {
    width: 22.5%;
  }
  .container {
    width: 90% !important;
  }
}
@media screen and (max-width: 480px) {
  .container.\3125\25 {
    width: 100%;
    max-width: 112.5%;
    min-width: 90%;
  }
  .container.\375\25 {
    width: 67.5%;
  }
  .container.\350\25 {
    width: 45%;
  }
  .container.\325\25 {
    width: 22.5%;
  }
  .container {
    width: 90% !important;
  }
}
/* Grid */
.row {
  border-bottom: solid 1px transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.row > * {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.row:after, .row:before {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
.row.uniform > * > :first-child {
  margin-top: 0;
}
.row.uniform > * > :last-child {
  margin-bottom: 0;
}
.row.\30\25 > * {
  padding: 0 0 0 0em;
}
.row.\30\25 {
  margin: 0 0 -1px 0em;
}
.row.uniform.\30\25 > * {
  padding: 0em 0 0 0em;
}
.row.uniform.\30\25 {
  margin: 0em 0 -1px 0em;
}
.row > * {
  padding: 0 0 0 2em;
}
.row {
  margin: 0 0 -1px -2em;
}
.row.uniform > * {
  padding: 2em 0 0 2em;
}
.row.uniform {
  margin: -2em 0 -1px -2em;
}
.row.\320\25 > * {
  padding: 0 0 0 4em;
}
.row.\320\25 {
  margin: 0 0 -1px -4em;
}
.row.uniform.\320\25 > * {
  padding: 4em 0 0 4em;
}
.row.uniform.\320\25 {
  margin: -4em 0 -1px -4em;
}
.row.\3150\25 > * {
  padding: 0 0 0 3em;
}
.row.\3150\25 {
  margin: 0 0 -1px -3em;
}
.row.uniform.\3150\25 > * {
  padding: 3em 0 0 3em;
}
.row.uniform.\3150\25 {
  margin: -3em 0 -1px -3em;
}
.row.\350\25 > * {
  padding: 0 0 0 1em;
}
.row.\350\25 {
  margin: 0 0 -1px -1em;
}
.row.uniform.\350\25 > * {
  padding: 1em 0 0 1em;
}
.row.uniform.\350\25 {
  margin: -1em 0 -1px -1em;
}
.row.\325\25 > * {
  padding: 0 0 0 0.5em;
}
.row.\325\25 {
  margin: 0 0 -1px -0.5em;
}
.row.uniform.\325\25 > * {
  padding: 0.5em 0 0 0.5em;
}
.row.uniform.\325\25 {
  margin: -0.5em 0 -1px -0.5em;
}
.\312u, .\312u\24 {
  width: 100%;
  clear: none;
  margin-left: 0;
}
.\311u, .\311u\24 {
  width: 91.6666666667%;
  clear: none;
  margin-left: 0;
}
.\310u, .\310u\24 {
  width: 83.3333333333%;
  clear: none;
  margin-left: 0;
}
.\39u, .\39u\24 {
  width: 75%;
  clear: none;
  margin-left: 0;
}
.\38u, .\38u\24 {
  width: 66.6666666667%;
  clear: none;
  margin-left: 0;
}
.\37u, .\37u\24 {
  width: 58.3333333333%;
  clear: none;
  margin-left: 0;
}
.\36u, .\36u\24 {
  width: 50%;
  clear: none;
  margin-left: 0;
}
.\35u, .\35u\24 {
  width: 41.6666666667%;
  clear: none;
  margin-left: 0;
}
.\34u, .\34u\24 {
  width: 33.3333333333%;
  clear: none;
  margin-left: 0;
}
.\33u, .\33u\24 {
  width: 25%;
  clear: none;
  margin-left: 0;
}
.\32u, .\32u\24 {
  width: 16.6666666667%;
  clear: none;
  margin-left: 0;
}
.\31u, .\31u\24 {
  width: 8.3333333333%;
  clear: none;
  margin-left: 0;
}
.\312u\24 + *, .\311u\24 + *, .\310u\24 + *, .\39u\24 + *, .\38u\24 + *, .\37u\24 + *, .\36u\24 + *, .\35u\24 + *, .\34u\24 + *, .\33u\24 + *, .\32u\24 + *, .\31u\24 + * {
  clear: left;
}
.\-11u {
  margin-left: 91.66667%;
}
.\-10u {
  margin-left: 83.33333%;
}
.\-9u {
  margin-left: 75%;
}
.\-8u {
  margin-left: 66.66667%;
}
.\-7u {
  margin-left: 58.33333%;
}
.\-6u {
  margin-left: 50%;
}
.\-5u {
  margin-left: 41.66667%;
}
.\-4u {
  margin-left: 33.33333%;
}
.\-3u {
  margin-left: 25%;
}
.\-2u {
  margin-left: 16.66667%;
}
.\-1u {
  margin-left: 8.33333%;
}
@media screen and (max-width: 1680px) {
  .row > * {
    padding: 0 0 0 2em;
  }
  .row {
    margin: 0 0 -1px -2em;
  }
  .row.uniform > * {
    padding: 2em 0 0 2em;
  }
  .row.uniform {
    margin: -2em 0 -1px -2em;
  }
  .row.\320\25 > * {
    padding: 0 0 0 4em;
  }
  .row.\320\25 {
    margin: 0 0 -1px -4em;
  }
  .row.uniform.\320\25 > * {
    padding: 4em 0 0 4em;
  }
  .row.uniform.\320\25 {
    margin: -4em 0 -1px -4em;
  }
  .row.\3150\25 > * {
    padding: 0 0 0 3em;
  }
  .row.\3150\25 {
    margin: 0 0 -1px -3em;
  }
  .row.uniform.\3150\25 > * {
    padding: 3em 0 0 3em;
  }
  .row.uniform.\3150\25 {
    margin: -3em 0 -1px -3em;
  }
  .row.\350\25 > * {
    padding: 0 0 0 1em;
  }
  .row.\350\25 {
    margin: 0 0 -1px -1em;
  }
  .row.uniform.\350\25 > * {
    padding: 1em 0 0 1em;
  }
  .row.uniform.\350\25 {
    margin: -1em 0 -1px -1em;
  }
  .row.\325\25 > * {
    padding: 0 0 0 0.5em;
  }
  .row.\325\25 {
    margin: 0 0 -1px -0.5em;
  }
  .row.uniform.\325\25 > * {
    padding: 0.5em 0 0 0.5em;
  }
  .row.uniform.\325\25 {
    margin: -0.5em 0 -1px -0.5em;
  }
  .\312u\28xlarge\29, .\312u\24\28xlarge\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\311u\28xlarge\29, .\311u\24\28xlarge\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\310u\28xlarge\29, .\310u\24\28xlarge\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39u\28xlarge\29, .\39u\24\28xlarge\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38u\28xlarge\29, .\38u\24\28xlarge\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37u\28xlarge\29, .\37u\24\28xlarge\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36u\28xlarge\29, .\36u\24\28xlarge\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35u\28xlarge\29, .\35u\24\28xlarge\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34u\28xlarge\29, .\34u\24\28xlarge\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33u\28xlarge\29, .\33u\24\28xlarge\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32u\28xlarge\29, .\32u\24\28xlarge\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31u\28xlarge\29, .\31u\24\28xlarge\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\312u\24\28xlarge\29 + *, .\311u\24\28xlarge\29 + *, .\310u\24\28xlarge\29 + *, .\39u\24\28xlarge\29 + *, .\38u\24\28xlarge\29 + *, .\37u\24\28xlarge\29 + *, .\36u\24\28xlarge\29 + *, .\35u\24\28xlarge\29 + *, .\34u\24\28xlarge\29 + *, .\33u\24\28xlarge\29 + *, .\32u\24\28xlarge\29 + *, .\31u\24\28xlarge\29 + * {
    clear: left;
  }
  .\-11u\28xlarge\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28xlarge\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28xlarge\29 {
    margin-left: 75%;
  }
  .\-8u\28xlarge\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28xlarge\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28xlarge\29 {
    margin-left: 50%;
  }
  .\-5u\28xlarge\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28xlarge\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28xlarge\29 {
    margin-left: 25%;
  }
  .\-2u\28xlarge\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28xlarge\29 {
    margin-left: 8.33333%;
  }
}
@media screen and (max-width: 1280px) {
  .row > * {
    padding: 0 0 0 1.5em;
  }
  .row {
    margin: 0 0 -1px -1.5em;
  }
  .row.uniform > * {
    padding: 1.5em 0 0 1.5em;
  }
  .row.uniform {
    margin: -1.5em 0 -1px -1.5em;
  }
  .row.\320\25 > * {
    padding: 0 0 0 3em;
  }
  .row.\320\25 {
    margin: 0 0 -1px -3em;
  }
  .row.uniform.\320\25 > * {
    padding: 3em 0 0 3em;
  }
  .row.uniform.\320\25 {
    margin: -3em 0 -1px -3em;
  }
  .row.\3150\25 > * {
    padding: 0 0 0 2.25em;
  }
  .row.\3150\25 {
    margin: 0 0 -1px -2.25em;
  }
  .row.uniform.\3150\25 > * {
    padding: 2.25em 0 0 2.25em;
  }
  .row.uniform.\3150\25 {
    margin: -2.25em 0 -1px -2.25em;
  }
  .row.\350\25 > * {
    padding: 0 0 0 0.75em;
  }
  .row.\350\25 {
    margin: 0 0 -1px -0.75em;
  }
  .row.uniform.\350\25 > * {
    padding: 0.75em 0 0 0.75em;
  }
  .row.uniform.\350\25 {
    margin: -0.75em 0 -1px -0.75em;
  }
  .row.\325\25 > * {
    padding: 0 0 0 0.375em;
  }
  .row.\325\25 {
    margin: 0 0 -1px -0.375em;
  }
  .row.uniform.\325\25 > * {
    padding: 0.375em 0 0 0.375em;
  }
  .row.uniform.\325\25 {
    margin: -0.375em 0 -1px -0.375em;
  }
  .\312u\28large\29, .\312u\24\28large\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\311u\28large\29, .\311u\24\28large\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\310u\28large\29, .\310u\24\28large\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39u\28large\29, .\39u\24\28large\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38u\28large\29, .\38u\24\28large\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37u\28large\29, .\37u\24\28large\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36u\28large\29, .\36u\24\28large\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35u\28large\29, .\35u\24\28large\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34u\28large\29, .\34u\24\28large\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33u\28large\29, .\33u\24\28large\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32u\28large\29, .\32u\24\28large\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31u\28large\29, .\31u\24\28large\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\312u\24\28large\29 + *, .\311u\24\28large\29 + *, .\310u\24\28large\29 + *, .\39u\24\28large\29 + *, .\38u\24\28large\29 + *, .\37u\24\28large\29 + *, .\36u\24\28large\29 + *, .\35u\24\28large\29 + *, .\34u\24\28large\29 + *, .\33u\24\28large\29 + *, .\32u\24\28large\29 + *, .\31u\24\28large\29 + * {
    clear: left;
  }
  .\-11u\28large\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28large\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28large\29 {
    margin-left: 75%;
  }
  .\-8u\28large\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28large\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28large\29 {
    margin-left: 50%;
  }
  .\-5u\28large\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28large\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28large\29 {
    margin-left: 25%;
  }
  .\-2u\28large\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28large\29 {
    margin-left: 8.33333%;
  }
}
@media screen and (max-width: 980px) {
  .row > * {
    padding: 0 0 0 1.5em;
  }
  .row {
    margin: 0 0 -1px -1.5em;
  }
  .row.uniform > * {
    padding: 1.5em 0 0 1.5em;
  }
  .row.uniform {
    margin: -1.5em 0 -1px -1.5em;
  }
  .row.\320\25 > * {
    padding: 0 0 0 3em;
  }
  .row.\320\25 {
    margin: 0 0 -1px -3em;
  }
  .row.uniform.\320\25 > * {
    padding: 3em 0 0 3em;
  }
  .row.uniform.\320\25 {
    margin: -3em 0 -1px -3em;
  }
  .row.\3150\25 > * {
    padding: 0 0 0 2.25em;
  }
  .row.\3150\25 {
    margin: 0 0 -1px -2.25em;
  }
  .row.uniform.\3150\25 > * {
    padding: 2.25em 0 0 2.25em;
  }
  .row.uniform.\3150\25 {
    margin: -2.25em 0 -1px -2.25em;
  }
  .row.\350\25 > * {
    padding: 0 0 0 0.75em;
  }
  .row.\350\25 {
    margin: 0 0 -1px -0.75em;
  }
  .row.uniform.\350\25 > * {
    padding: 0.75em 0 0 0.75em;
  }
  .row.uniform.\350\25 {
    margin: -0.75em 0 -1px -0.75em;
  }
  .row.\325\25 > * {
    padding: 0 0 0 0.375em;
  }
  .row.\325\25 {
    margin: 0 0 -1px -0.375em;
  }
  .row.uniform.\325\25 > * {
    padding: 0.375em 0 0 0.375em;
  }
  .row.uniform.\325\25 {
    margin: -0.375em 0 -1px -0.375em;
  }
  .\312u\28medium\29, .\312u\24\28medium\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\311u\28medium\29, .\311u\24\28medium\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\310u\28medium\29, .\310u\24\28medium\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39u\28medium\29, .\39u\24\28medium\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38u\28medium\29, .\38u\24\28medium\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37u\28medium\29, .\37u\24\28medium\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36u\28medium\29, .\36u\24\28medium\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35u\28medium\29, .\35u\24\28medium\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34u\28medium\29, .\34u\24\28medium\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33u\28medium\29, .\33u\24\28medium\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32u\28medium\29, .\32u\24\28medium\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31u\28medium\29, .\31u\24\28medium\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\312u\24\28medium\29 + *, .\311u\24\28medium\29 + *, .\310u\24\28medium\29 + *, .\39u\24\28medium\29 + *, .\38u\24\28medium\29 + *, .\37u\24\28medium\29 + *, .\36u\24\28medium\29 + *, .\35u\24\28medium\29 + *, .\34u\24\28medium\29 + *, .\33u\24\28medium\29 + *, .\32u\24\28medium\29 + *, .\31u\24\28medium\29 + * {
    clear: left;
  }
  .\-11u\28medium\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28medium\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28medium\29 {
    margin-left: 75%;
  }
  .\-8u\28medium\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28medium\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28medium\29 {
    margin-left: 50%;
  }
  .\-5u\28medium\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28medium\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28medium\29 {
    margin-left: 25%;
  }
  .\-2u\28medium\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28medium\29 {
    margin-left: 8.33333%;
  }
}
@media screen and (max-width: 736px) {
  .row > * {
    padding: 0 0 0 1.25em;
  }
  .row {
    margin: 0 0 -1px -1.25em;
  }
  .row.uniform > * {
    padding: 1.25em 0 0 1.25em;
  }
  .row.uniform {
    margin: -1.25em 0 -1px -1.25em;
  }
  .row.\320\25 > * {
    padding: 0 0 0 2.5em;
  }
  .row.\320\25 {
    margin: 0 0 -1px -2.5em;
  }
  .row.uniform.\320\25 > * {
    padding: 2.5em 0 0 2.5em;
  }
  .row.uniform.\320\25 {
    margin: -2.5em 0 -1px -2.5em;
  }
  .row.\3150\25 > * {
    padding: 0 0 0 1.875em;
  }
  .row.\3150\25 {
    margin: 0 0 -1px -1.875em;
  }
  .row.uniform.\3150\25 > * {
    padding: 1.875em 0 0 1.875em;
  }
  .row.uniform.\3150\25 {
    margin: -1.875em 0 -1px -1.875em;
  }
  .row.\350\25 > * {
    padding: 0 0 0 0.625em;
  }
  .row.\350\25 {
    margin: 0 0 -1px -0.625em;
  }
  .row.uniform.\350\25 > * {
    padding: 0.625em 0 0 0.625em;
  }
  .row.uniform.\350\25 {
    margin: -0.625em 0 -1px -0.625em;
  }
  .row.\325\25 > * {
    padding: 0 0 0 0.3125em;
  }
  .row.\325\25 {
    margin: 0 0 -1px -0.3125em;
  }
  .row.uniform.\325\25 > * {
    padding: 0.3125em 0 0 0.3125em;
  }
  .row.uniform.\325\25 {
    margin: -0.3125em 0 -1px -0.3125em;
  }
  .\312u\28small\29, .\312u\24\28small\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\311u\28small\29, .\311u\24\28small\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\310u\28small\29, .\310u\24\28small\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39u\28small\29, .\39u\24\28small\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38u\28small\29, .\38u\24\28small\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37u\28small\29, .\37u\24\28small\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36u\28small\29, .\36u\24\28small\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35u\28small\29, .\35u\24\28small\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34u\28small\29, .\34u\24\28small\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33u\28small\29, .\33u\24\28small\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32u\28small\29, .\32u\24\28small\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31u\28small\29, .\31u\24\28small\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\312u\24\28small\29 + *, .\311u\24\28small\29 + *, .\310u\24\28small\29 + *, .\39u\24\28small\29 + *, .\38u\24\28small\29 + *, .\37u\24\28small\29 + *, .\36u\24\28small\29 + *, .\35u\24\28small\29 + *, .\34u\24\28small\29 + *, .\33u\24\28small\29 + *, .\32u\24\28small\29 + *, .\31u\24\28small\29 + * {
    clear: left;
  }
  .\-11u\28small\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28small\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28small\29 {
    margin-left: 75%;
  }
  .\-8u\28small\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28small\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28small\29 {
    margin-left: 50%;
  }
  .\-5u\28small\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28small\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28small\29 {
    margin-left: 25%;
  }
  .\-2u\28small\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28small\29 {
    margin-left: 8.33333%;
  }
}
@media screen and (max-width: 480px) {
  .row > * {
    padding: 0 0 0 1.25em;
  }
  .row {
    margin: 0 0 -1px -1.25em;
  }
  .row.uniform > * {
    padding: 1.25em 0 0 1.25em;
  }
  .row.uniform {
    margin: -1.25em 0 -1px -1.25em;
  }
  .row.\320\25 > * {
    padding: 0 0 0 2.5em;
  }
  .row.\320\25 {
    margin: 0 0 -1px -2.5em;
  }
  .row.uniform.\320\25 > * {
    padding: 2.5em 0 0 2.5em;
  }
  .row.uniform.\320\25 {
    margin: -2.5em 0 -1px -2.5em;
  }
  .row.\3150\25 > * {
    padding: 0 0 0 1.875em;
  }
  .row.\3150\25 {
    margin: 0 0 -1px -1.875em;
  }
  .row.uniform.\3150\25 > * {
    padding: 1.875em 0 0 1.875em;
  }
  .row.uniform.\3150\25 {
    margin: -1.875em 0 -1px -1.875em;
  }
  .row.\350\25 > * {
    padding: 0 0 0 0.625em;
  }
  .row.\350\25 {
    margin: 0 0 -1px -0.625em;
  }
  .row.uniform.\350\25 > * {
    padding: 0.625em 0 0 0.625em;
  }
  .row.uniform.\350\25 {
    margin: -0.625em 0 -1px -0.625em;
  }
  .row.\325\25 > * {
    padding: 0 0 0 0.3125em;
  }
  .row.\325\25 {
    margin: 0 0 -1px -0.3125em;
  }
  .row.uniform.\325\25 > * {
    padding: 0.3125em 0 0 0.3125em;
  }
  .row.uniform.\325\25 {
    margin: -0.3125em 0 -1px -0.3125em;
  }
  .\312u\28xsmall\29, .\312u\24\28xsmall\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }
  .\311u\28xsmall\29, .\311u\24\28xsmall\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\310u\28xsmall\29, .\310u\24\28xsmall\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\39u\28xsmall\29, .\39u\24\28xsmall\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }
  .\38u\28xsmall\29, .\38u\24\28xsmall\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\37u\28xsmall\29, .\37u\24\28xsmall\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\36u\28xsmall\29, .\36u\24\28xsmall\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }
  .\35u\28xsmall\29, .\35u\24\28xsmall\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\34u\28xsmall\29, .\34u\24\28xsmall\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\33u\28xsmall\29, .\33u\24\28xsmall\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }
  .\32u\28xsmall\29, .\32u\24\28xsmall\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }
  .\31u\28xsmall\29, .\31u\24\28xsmall\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }
  .\312u\24\28xsmall\29 + *, .\311u\24\28xsmall\29 + *, .\310u\24\28xsmall\29 + *, .\39u\24\28xsmall\29 + *, .\38u\24\28xsmall\29 + *, .\37u\24\28xsmall\29 + *, .\36u\24\28xsmall\29 + *, .\35u\24\28xsmall\29 + *, .\34u\24\28xsmall\29 + *, .\33u\24\28xsmall\29 + *, .\32u\24\28xsmall\29 + *, .\31u\24\28xsmall\29 + * {
    clear: left;
  }
  .\-11u\28xsmall\29 {
    margin-left: 91.66667%;
  }
  .\-10u\28xsmall\29 {
    margin-left: 83.33333%;
  }
  .\-9u\28xsmall\29 {
    margin-left: 75%;
  }
  .\-8u\28xsmall\29 {
    margin-left: 66.66667%;
  }
  .\-7u\28xsmall\29 {
    margin-left: 58.33333%;
  }
  .\-6u\28xsmall\29 {
    margin-left: 50%;
  }
  .\-5u\28xsmall\29 {
    margin-left: 41.66667%;
  }
  .\-4u\28xsmall\29 {
    margin-left: 33.33333%;
  }
  .\-3u\28xsmall\29 {
    margin-left: 25%;
  }
  .\-2u\28xsmall\29 {
    margin-left: 16.66667%;
  }
  .\-1u\28xsmall\29 {
    margin-left: 8.33333%;
  }
}
/* Basic */
@-ms-viewport {
  width: device-width;
}
body {
  -ms-overflow-style: scrollbar;
}
@media screen and (max-width: 480px) {
  html, body {
    min-width: 320px;
  }
}
body {
  background: #fff;
}
body.is-loading *, body.is-loading *:before, body.is-loading *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}
/* Type */
body {
  background-color: #fff;
  color: #555;
}
body, input, select, textarea {
  font-family: "Raleway", Helvetica, sans-serif;
  font-size: 13pt;
  font-weight: 400;
  line-height: 2em;
}
@media screen and (max-width: 1680px) {
  body, input, select, textarea {
    font-size: 11pt;
  }
}
@media screen and (max-width: 1280px) {
  body, input, select, textarea {
    font-size: 11pt;
  }
}
@media screen and (max-width: 980px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}
@media screen and (max-width: 736px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}
@media screen and (max-width: 480px) {
  body, input, select, textarea {
    font-size: 12pt;
  }
}
a {
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
strong, b {
  font-weight: 700;
}
em, i {
  font-style: italic;
}
p {
  margin: 0 0 2em 0;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1em;
  margin: 0 0 1em 0;
  text-transform: uppercase;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  text-decoration: none;
}
h2 {
  font-size: 1.5em;
  line-height: 1.5em;
}
h3 {
  font-size: 1.3em;
  line-height: 1.5em;
}
h4 {
  font-size: 1.1em;
  line-height: 1.5em;
}
h5 {
  font-size: 0.9em;
  line-height: 1.5em;
}
h6 {
  font-size: 0.7em;
  line-height: 1.5em;
}
sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}
sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}
blockquote {
  border-left-style: solid;
  border-left-width: 4px;
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}
code {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}
pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0;
}
pre code {
  display: block;
  line-height: 1.75em;
  padding: 1em 1.5em;
  overflow-x: auto;
}
hr {
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: 2em 0;
}
hr.major {
  margin: 3em 0;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
input, select, textarea {
  color: #25383B;
}
a {
  color: #415A77;
}
strong, b {
  color: #25383B;
}
h1, h2, h3, h4, h5, h6 {
  color: #25383B;
}
blockquote {
  border-left-color: rgba(144, 144, 144, 0.25);
}
code {
  background: rgba(144, 144, 144, 0.075);
  border-color: rgba(144, 144, 144, 0.25);
}
hr {
  border-bottom-color: rgba(144, 144, 144, 0.25);
}
/* Section/Article */
section.special, article.special, header.special {
  text-align: center;
}
header p {
  margin: 0 0 1.5em 0;
  position: relative;
  text-transform: uppercase;
}
header h2 + p {
  font-size: 1.25em;
  margin-top: -1em;
  line-height: 1.85em;
}
header h3 + p {
  font-size: 1.1em;
  margin-top: -0.8em;
  line-height: 1.5em;
}
header h4 + p, header h5 + p, header h6 + p {
  font-size: 0.9em;
  margin-top: -0.6em;
  line-height: 1.5em;
}
header.major {
  margin-bottom: 4em;
}
header.major h2 {
  font-size: 2.5em;
}
header.narrow {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1280px) {
  header.narrow {
    max-width: 75%;
  }
}
@media screen and (max-width: 980px) {
  header.narrow {
    max-width: 100%;
  }
  header.major {
    margin-bottom: 2em;
  }
  header.major h2 {
    font-size: 2em;
  }
}
@media screen and (max-width: 736px) {
  header.major h2 {
    font-size: 1.5em;
  }
  header.major p {
    font-size: 1em;
  }
}
header p {
  color: #25383B;
}
/* Form */
form {
  margin: 0 0 2em 0;
}
form ul.actions {
  margin-top: 4em;
}
@media screen and (max-width: 736px) {
  form ul.actions {
    margin-top: 2em;
  }
}
label {
  display: block;
  font-size: 0.9em;
  font-weight: 700;
  margin: 0 0 1em 0;
}
input[type="text"], input[type="password"], input[type="email"], select, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}
input[type="text"]:invalid, input[type="password"]:invalid, input[type="email"]:invalid, select:invalid, textarea:invalid {
  box-shadow: none;
}
.select-wrapper {
  text-decoration: none;
  display: block;
  position: relative;
}
.select-wrapper:before {
  content: "";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
.select-wrapper:before {
  display: block;
  height: 3.5em;
  line-height: 3.5em;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 3.5em;
}
.select-wrapper select::-ms-expand {
  display: none;
}
input[type="text"], input[type="password"], input[type="email"], select {
  height: 3.5em;
}
textarea {
  padding: 0.75em 1em;
}
input[type="checkbox"], input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;
}
input[type="checkbox"] + label, input[type="radio"] + label {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  padding-left: 2.85em;
  padding-right: 0.75em;
  position: relative;
}
input[type="checkbox"] + label:before, input[type="radio"] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
input[type="checkbox"] + label:before, input[type="radio"] + label:before {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  content: '';
  display: inline-block;
  height: 2.1em;
  left: 0;
  line-height: 2.0125em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 2.1em;
}
input[type="checkbox"]:checked + label:before, input[type="radio"]:checked + label:before {
  content: '\f00c';
}
input[type="checkbox"] + label:before {
  border-radius: 4px;
}
input[type="radio"] + label:before {
  border-radius: 100%;
}
::-webkit-input-placeholder {
  opacity: 1;
}
:-moz-placeholder {
  opacity: 1;
}
::-moz-placeholder {
  opacity: 1;
}
:-ms-input-placeholder {
  opacity: 1;
}
.formerize-placeholder {
  opacity: 1;
}
label {
  color: #25383B;
}
input[type="text"], input[type="password"], input[type="email"], select, textarea {
  background-color: rgba(144, 144, 144, 0.075);
  border-color: rgba(144, 144, 144, 0.25);
  color: inherit;
}
input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, select:focus, textarea:focus {
  border-color: #415A77;
  box-shadow: 0 0 0 1px #415A77;
}
.select-wrapper:before {
  color: rgba(144, 144, 144, 0.25);
}
input[type="checkbox"] + label, input[type="radio"] + label {
  color: #555;
}
input[type="checkbox"] + label:before, input[type="radio"] + label:before {
  background-color: rgba(144, 144, 144, 0.075);
  border-color: rgba(144, 144, 144, 0.25);
}
input[type="checkbox"]:checked + label:before, input[type="radio"]:checked + label:before {
  background-color: rgba(144, 144, 144, 0.075);
  border-color: #415A77;
  color: #25383B;
}
input[type="checkbox"]:focus + label:before, input[type="radio"]:focus + label:before {
  border-color: #415A77;
  box-shadow: 0 0 0 1px #415A77;
}
::-webkit-input-placeholder {
  color: #999 !important;
}
:-moz-placeholder {
  color: #999 !important;
}
::-moz-placeholder {
  color: #999 !important;
}
:-ms-input-placeholder {
  color: #999 !important;
}
.formerize-placeholder {
  color: #999 !important;
}
form.alt input[type="text"], form.alt input[type="password"], form.alt input[type="email"], form.alt select, form.alt textarea {
  background-color: transparent;
}
/* Icon */
.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}
.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
.icon > .label {
  display: none;
}
/* Image */
.image {
  border-radius: 0;
  border: 0;
  display: inline-block;
  position: relative;
}
.image img {
  border-radius: 0;
  display: block;
}
.image.left, .image.right {
  max-width: 40%;
}
.image.left img, .image.right img {
  width: 100%;
}
.image.left {
  float: left;
  padding: 0 1.5em 1em 0;
  top: 0.25em;
}
.image.right {
  float: right;
  padding: 0 0 1em 1.5em;
  top: 0.25em;
}
.image.fit {
  display: block;
  margin: 0 0 2em 0;
  width: 100%;
}
.image.fit img {
  width: 100%;
}
.image.main {
  display: block;
  margin: 0 0 3em 0;
  width: 100%;
}
.image.main img {
  width: 100%;
}
/* List */
ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}
ol li {
  padding-left: 0.25em;
}
ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}
ul li {
  padding-left: 0.5em;
}
ul.alt {
  list-style: none;
  padding-left: 0;
}
ul.alt > li {
  border-top-style: solid;
  border-top-width: 1px;
  padding: 0.5em 0;
}
ul.alt > li:first-child {
  border-top: 0;
  padding-top: 0;
}
ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}
ul.icons > li {
  display: inline-block;
  padding: 0 1em 0 0;
}
ul.icons > li:last-child {
  padding-right: 0;
}
ul.icons > li .icon:before {
  font-size: 2em;
}
ul.actions {
  cursor: default;
  list-style: none;
  padding-left: 0;
}
ul.actions > li {
  display: inline-block;
  padding: 0 1em 0 0;
  vertical-align: middle;
}
ul.actions > li:last-child {
  padding-right: 0;
}
ul.actions.small > li {
  padding: 0 0.5em 0 0;
}
ul.actions.vertical > li {
  display: block;
  padding: 1em 0 0 0;
}
ul.actions.vertical > li:first-child {
  padding-top: 0;
}
ul.actions.vertical > li > * {
  margin-bottom: 0;
}
ul.actions.vertical.small > li {
  padding: 0.5em 0 0 0;
}
ul.actions.vertical.small > li:first-child {
  padding-top: 0;
}
ul.actions.fit {
  display: table;
  margin-left: -1em;
  padding: 0;
  table-layout: fixed;
  width: calc(100% + 1em);
}
ul.actions.fit > li {
  display: table-cell;
  padding: 0 0 0 1em;
}
ul.actions.fit > li > * {
  margin-bottom: 0;
}
ul.actions.fit.small {
  margin-left: -0.5em;
  width: calc(100% + 0.5em);
}
ul.actions.fit.small > li {
  padding: 0 0 0 0.5em;
}
@media screen and (max-width: 480px) {
  ul.actions {
    margin: 0 0 2em 0;
  }
  ul.actions > li {
    padding: 1em 0 0 0;
    display: block;
    text-align: center;
    width: 100%;
  }
  ul.actions > li:first-child {
    padding-top: 0;
  }
  ul.actions > li > * {
    width: 100%;
    margin: 0 !important;
  }
  ul.actions > li > *.icon:before {
    margin-left: -2em;
  }
  ul.actions.small > li {
    padding: 0.5em 0 0 0;
  }
  ul.actions.small > li:first-child {
    padding-top: 0;
  }
}
dl {
  margin: 0 0 2em 0;
}
dl > dt {
  display: block;
  font-weight: 700;
  margin: 0 0 1em 0;
}
dl > dd {
  margin-left: 2em;
}
ul.alt > li {
  border-top-color: rgba(144, 144, 144, 0.25);
}
/* Button */
input[type="submit"], input[type="reset"], input[type="button"], button, .button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 2.5em;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  height: 2.85em;
  line-height: 2.95em;
  min-width: 10em;
  padding: 0 1.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}
input[type="submit"].icon, input[type="reset"].icon, input[type="button"].icon, button.icon, .button.icon {
  padding-left: 1.35em;
}
input[type="submit"].icon:before, input[type="reset"].icon:before, input[type="button"].icon:before, button.icon:before, .button.icon:before {
  margin-right: 0.5em;
}
input[type="submit"].fit, input[type="reset"].fit, input[type="button"].fit, button.fit, .button.fit {
  display: block;
  margin: 0 0 1em 0;
  width: 100%;
}
input[type="submit"].small, input[type="reset"].small, input[type="button"].small, button.small, .button.small {
  font-size: 0.8em;
}
input[type="submit"].big, input[type="reset"].big, input[type="button"].big, button.big, .button.big {
  font-size: 1.2em;
  height: 3.25em;
  line-height: 3.25em;
  padding: 0 2.5em;
}
input[type="submit"].disabled, input[type="submit"]:disabled, input[type="reset"].disabled, input[type="reset"]:disabled, input[type="button"].disabled, input[type="button"]:disabled, button.disabled, button:disabled, .button.disabled, .button:disabled {
  cursor: default;
  opacity: 0.25;
}
@media screen and (max-width: 980px) {
  input[type="submit"].big, input[type="reset"].big, input[type="button"].big, button.big, .button.big {
    font-size: 1em;
  }
}
@media screen and (max-width: 480px) {
  input[type="submit"], input[type="reset"], input[type="button"], button, .button {
    padding: 0;
  }
}
input[type="submit"], input[type="reset"], input[type="button"], button, .button {
  background-color: #25383B;
  box-shadow: none;
  color: #fff !important;
}
input[type="submit"].icon:before, input[type="reset"].icon:before, input[type="button"].icon:before, button.icon:before, .button.icon:before {
  color: #999;
}
input[type="submit"]:hover, input[type="reset"]:hover, input[type="button"]:hover, button:hover, .button:hover {
  background-color: #0D1B2A;
}
input[type="submit"]:active, input[type="reset"]:active, input[type="button"]:active, button:active, .button:active {
  background-color: #1b292b;
}
input[type="submit"].special, input[type="reset"].special, input[type="button"].special, button.special, .button.special {
  background-color: #415A77;
  box-shadow: none;
  color: #fff !important;
}
input[type="submit"].special:hover, input[type="reset"].special:hover, input[type="button"].special:hover, button.special:hover, .button.special:hover {
  background-color: #415A77;
}
input[type="submit"].special:active, input[type="reset"].special:active, input[type="button"].special:active, button.special:active, .button.special:active {
  background-color: #45ad97;
}
input[type="submit"].alt, input[type="reset"].alt, input[type="button"].alt, button.alt, .button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #415A77;
  color: #415A77 !important;
}
input[type="submit"].alt:hover, input[type="reset"].alt:hover, input[type="button"].alt:hover, button.alt:hover, .button.alt:hover {
  background-color: rgba(144, 144, 144, 0.075);
}
input[type="submit"].alt:active, input[type="reset"].alt:active, input[type="button"].alt:active, button.alt:active, .button.alt:active {
  background-color: rgba(144, 144, 144, 0.2);
}
/* Feature */
.feature {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 6em;
}
.feature:last-child {
  margin-bottom: 2em;
}
.feature.left {
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.feature.right {
  -moz-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.feature .image {
  width: 48%;
}
.feature .image img {
  max-width: 100%;
}
.feature .content {
  padding: 2em 3em 0.1em 3em;
  width: 52%;
}
@media screen and (max-width: 1280px) {
  .feature {
    margin-bottom: 4em;
  }
  .feature .content {
    padding: 1em 2em 0.1em 2em;
  }
}
@media screen and (max-width: 980px) {
  .feature.left, .feature.right {
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .feature .image {
    width: 100%;
  }
  .feature .image img {
    width: 100%;
  }
  .feature .content {
    padding: 2em 2em 0.1em 2em;
    width: 100%;
  }
}
@media screen and (max-width: 736px) {
  .feature {
    margin-bottom: 2em;
  }
  .feature .content {
    padding: 2em 1em 0.1em 1em;
  }
}
.feature {
  background-color: rgba(144, 144, 144, 0.075);
}
/* Image Grid */
.image-grid {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 4em;
}
.image-grid .image {
  margin: 0;
  width: 25%;
}
.image-grid .image img {
  width: 100%;
}
@media screen and (max-width: 980px) {
  .image-grid {
    margin-bottom: 3em;
  }
  .image-grid .image {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .image-grid {
    margin-bottom: 2em;
  }
  .image-grid .image {
    width: 100%;
  }
}
/* Wrapper */
.wrapper {
  padding: 8em 0 6em 0;
  position: relative;
}
.wrapper > .inner {
  margin: 0 auto;
  width: 70em;
}
.wrapper.style1 {
  background-color: #E0E1DD;
  color: #555;
}
.wrapper.style1 input, .wrapper.style1 select, .wrapper.style1 textarea {
  color: #555;
}
.wrapper.style1 a {
  color: #415A77;
}
.wrapper.style1 strong, .wrapper.style1 b {
  color: #555;
}
.wrapper.style1 h1, .wrapper.style1 h2, .wrapper.style1 h3, .wrapper.style1 h4, .wrapper.style1 h5, .wrapper.style1 h6 {
  color: #555;
}
.wrapper.style1 blockquote {
  border-left-color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 code {
  background: #fff;
  border-color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 hr {
  border-bottom-color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 header p {
  color: #555;
}
.wrapper.style1 .feature {
  background-color: #fff;
}
.wrapper.style1 label {
  color: #555;
}
.wrapper.style1 input[type="text"], .wrapper.style1 input[type="password"], .wrapper.style1 input[type="email"], .wrapper.style1 select, .wrapper.style1 textarea {
  background-color: #fff;
  border-color: rgba(144, 144, 144, 0.25);
  color: inherit;
}
.wrapper.style1 input[type="text"]:focus, .wrapper.style1 input[type="password"]:focus, .wrapper.style1 input[type="email"]:focus, .wrapper.style1 select:focus, .wrapper.style1 textarea:focus {
  border-color: #415A77;
  box-shadow: 0 0 0 1px #415A77;
}
.wrapper.style1 .select-wrapper:before {
  color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 input[type="checkbox"] + label, .wrapper.style1 input[type="radio"] + label {
  color: #777;
}
.wrapper.style1 input[type="checkbox"] + label:before, .wrapper.style1 input[type="radio"] + label:before {
  background-color: #fff;
  border-color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 input[type="checkbox"]:checked + label:before, .wrapper.style1 input[type="radio"]:checked + label:before {
  background-color: #fff;
  border-color: #415A77;
  color: #555;
}
.wrapper.style1 input[type="checkbox"]:focus + label:before, .wrapper.style1 input[type="radio"]:focus + label:before {
  border-color: #415A77;
  box-shadow: 0 0 0 1px #415A77;
}
.wrapper.style1 ::-webkit-input-placeholder {
  color: #bbb !important;
}
.wrapper.style1 :-moz-placeholder {
  color: #bbb !important;
}
.wrapper.style1 ::-moz-placeholder {
  color: #bbb !important;
}
.wrapper.style1 :-ms-input-placeholder {
  color: #bbb !important;
}
.wrapper.style1 .formerize-placeholder {
  color: #bbb !important;
}
.wrapper.style1 form.alt input[type="text"], .wrapper.style1 form.alt input[type="password"], .wrapper.style1 form.alt input[type="email"], .wrapper.style1 form.alt select, .wrapper.style1 form.alt textarea {
  background-color: transparent;
}
.wrapper.style1 ul.alt > li {
  border-top-color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 table tbody tr {
  border-color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 table tbody tr:nth-child(2n + 1) {
  background-color: #fff;
}
.wrapper.style1 table th {
  color: #555;
}
.wrapper.style1 table thead {
  border-bottom-color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 table tfoot {
  border-top-color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 table.alt tbody tr td {
  border-color: rgba(144, 144, 144, 0.25);
}
.wrapper.style1 input[type="submit"], .wrapper.style1 input[type="reset"], .wrapper.style1 input[type="button"], .wrapper.style1 button, .wrapper.style1 .button {
  background-color: #555;
  box-shadow: none;
  color: #E0E1DD !important;
}
.wrapper.style1 input[type="submit"].icon:before, .wrapper.style1 input[type="reset"].icon:before, .wrapper.style1 input[type="button"].icon:before, .wrapper.style1 button.icon:before, .wrapper.style1 .button.icon:before {
  color: #bbb;
}
.wrapper.style1 input[type="submit"]:hover, .wrapper.style1 input[type="reset"]:hover, .wrapper.style1 input[type="button"]:hover, .wrapper.style1 button:hover, .wrapper.style1 .button:hover {
  background-color: #626262;
}
.wrapper.style1 input[type="submit"]:active, .wrapper.style1 input[type="reset"]:active, .wrapper.style1 input[type="button"]:active, .wrapper.style1 button:active, .wrapper.style1 .button:active {
  background-color: #484848;
}
.wrapper.style1 input[type="submit"].special, .wrapper.style1 input[type="reset"].special, .wrapper.style1 input[type="button"].special, .wrapper.style1 button.special, .wrapper.style1 .button.special {
  background-color: #415A77;
  box-shadow: none;
  color: #fff !important;
}
.wrapper.style1 input[type="submit"].special:hover, .wrapper.style1 input[type="reset"].special:hover, .wrapper.style1 input[type="button"].special:hover, .wrapper.style1 button.special:hover, .wrapper.style1 .button.special:hover {
  background-color: #415A77;
}
.wrapper.style1 input[type="submit"].special:active, .wrapper.style1 input[type="reset"].special:active, .wrapper.style1 input[type="button"].special:active, .wrapper.style1 button.special:active, .wrapper.style1 .button.special:active {
  background-color: #415A77;
}
.wrapper.style1 input[type="submit"].alt, .wrapper.style1 input[type="reset"].alt, .wrapper.style1 input[type="button"].alt, .wrapper.style1 button.alt, .wrapper.style1 .button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #415A77;
  color: #415A77 !important;
}
.wrapper.style1 input[type="submit"].alt:hover, .wrapper.style1 input[type="reset"].alt:hover, .wrapper.style1 input[type="button"].alt:hover, .wrapper.style1 button.alt:hover, .wrapper.style1 .button.alt:hover {
  background-color: #fff;
}
.wrapper.style1 input[type="submit"].alt:active, .wrapper.style1 input[type="reset"].alt:active, .wrapper.style1 input[type="button"].alt:active, .wrapper.style1 button.alt:active, .wrapper.style1 .button.alt:active {
  background-color: rgba(144, 144, 144, 0.2);
}
.wrapper.style2 {
  background-color: #0D1B2A;
  color: rgba(255, 255, 255, 0.75);
}
.wrapper.style2 input, .wrapper.style2 select, .wrapper.style2 textarea {
  color: #fff;
}
.wrapper.style2 a {
  color: #415A77;
}
.wrapper.style2 strong, .wrapper.style2 b {
  color: #fff;
}
.wrapper.style2 h1, .wrapper.style2 h2, .wrapper.style2 h3, .wrapper.style2 h4, .wrapper.style2 h5, .wrapper.style2 h6 {
  color: #fff;
}
.wrapper.style2 blockquote {
  border-left-color: transparent;
}
.wrapper.style2 code {
  background: rgba(255, 255, 255, 0.05);
  border-color: transparent;
}
.wrapper.style2 hr {
  border-bottom-color: transparent;
}
.wrapper.style2 header p {
  color: #fff;
}
.wrapper.style2 .feature {
  background-color: rgba(255, 255, 255, 0.05);
}
.wrapper.style2 label {
  color: #fff;
}
.wrapper.style2 input[type="text"], .wrapper.style2 input[type="password"], .wrapper.style2 input[type="email"], .wrapper.style2 select, .wrapper.style2 textarea {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: transparent;
  color: inherit;
}
.wrapper.style2 input[type="text"]:focus, .wrapper.style2 input[type="password"]:focus, .wrapper.style2 input[type="email"]:focus, .wrapper.style2 select:focus, .wrapper.style2 textarea:focus {
  border-color: #415A77;
  box-shadow: 0 0 0 1px #415A77;
}
.wrapper.style2 .select-wrapper:before {
  color: transparent;
}
.wrapper.style2 input[type="checkbox"] + label, .wrapper.style2 input[type="radio"] + label {
  color: rgba(255, 255, 255, 0.75);
}
.wrapper.style2 input[type="checkbox"] + label:before, .wrapper.style2 input[type="radio"] + label:before {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: transparent;
}
.wrapper.style2 input[type="checkbox"]:checked + label:before, .wrapper.style2 input[type="radio"]:checked + label:before {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: #415A77;
  color: #fff;
}
.wrapper.style2 input[type="checkbox"]:focus + label:before, .wrapper.style2 input[type="radio"]:focus + label:before {
  border-color: #415A77;
  box-shadow: 0 0 0 1px #415A77;
}
.wrapper.style2 ::-webkit-input-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style2 :-moz-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style2 ::-moz-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style2 :-ms-input-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style2 .formerize-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style2 form.alt input[type="text"], .wrapper.style2 form.alt input[type="password"], .wrapper.style2 form.alt input[type="email"], .wrapper.style2 form.alt select, .wrapper.style2 form.alt textarea {
  background-color: transparent;
}
.wrapper.style2 ul.alt > li {
  border-top-color: transparent;
}
.wrapper.style2 table tbody tr {
  border-color: transparent;
}
.wrapper.style2 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.05);
}
.wrapper.style2 table th {
  color: #fff;
}
.wrapper.style2 table thead {
  border-bottom-color: transparent;
}
.wrapper.style2 table tfoot {
  border-top-color: transparent;
}
.wrapper.style2 table.alt tbody tr td {
  border-color: transparent;
}
.wrapper.style2 input[type="submit"], .wrapper.style2 input[type="reset"], .wrapper.style2 input[type="button"], .wrapper.style2 button, .wrapper.style2 .button {
  background-color: #fff;
  box-shadow: none;
  color: #1E2832 !important;
}
.wrapper.style2 input[type="submit"].icon:before, .wrapper.style2 input[type="reset"].icon:before, .wrapper.style2 input[type="button"].icon:before, .wrapper.style2 button.icon:before, .wrapper.style2 .button.icon:before {
  color: #a5a9ad;
}
.wrapper.style2 input[type="submit"]:hover, .wrapper.style2 input[type="reset"]:hover, .wrapper.style2 input[type="button"]:hover, .wrapper.style2 button:hover, .wrapper.style2 .button:hover {
  background-color: white;
}
.wrapper.style2 input[type="submit"]:active, .wrapper.style2 input[type="reset"]:active, .wrapper.style2 input[type="button"]:active, .wrapper.style2 button:active, .wrapper.style2 .button:active {
  background-color: #f2f2f2;
}
.wrapper.style2 input[type="submit"].special, .wrapper.style2 input[type="reset"].special, .wrapper.style2 input[type="button"].special, .wrapper.style2 button.special, .wrapper.style2 .button.special {
  background-color: #415A77;
  box-shadow: none;
  color: #fff !important;
}
.wrapper.style2 input[type="submit"].special:hover, .wrapper.style2 input[type="reset"].special:hover, .wrapper.style2 input[type="button"].special:hover, .wrapper.style2 button.special:hover, .wrapper.style2 .button.special:hover {
  background-color: #415A77;
}
.wrapper.style2 input[type="submit"].special:active, .wrapper.style2 input[type="reset"].special:active, .wrapper.style2 input[type="button"].special:active, .wrapper.style2 button.special:active, .wrapper.style2 .button.special:active {
  background-color: #45ad97;
}
.wrapper.style2 input[type="submit"].alt, .wrapper.style2 input[type="reset"].alt, .wrapper.style2 input[type="button"].alt, .wrapper.style2 button.alt, .wrapper.style2 .button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff !important;
}
.wrapper.style2 input[type="submit"].alt:hover, .wrapper.style2 input[type="reset"].alt:hover, .wrapper.style2 input[type="button"].alt:hover, .wrapper.style2 button.alt:hover, .wrapper.style2 .button.alt:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.wrapper.style2 input[type="submit"].alt:active, .wrapper.style2 input[type="reset"].alt:active, .wrapper.style2 input[type="button"].alt:active, .wrapper.style2 button.alt:active, .wrapper.style2 .button.alt:active {
  background-color: rgba(255, 255, 255, 0.1);
}
.wrapper.style3 {
  background-color: #0D1B2A;
  color: rgba(255, 255, 255, 0.75);
  background-attachment: fixed;
  background-image: url("./assets/overlay.png"), url("./assets/pic11.jpg");
  background-size: cover;
}
.webp .wrapper.style3 {
  background-image: url("./assets/overlay.png"), url("./assets/pic11.webp");
}
.wrapper.style3 input, .wrapper.style3 select, .wrapper.style3 textarea {
  color: #fff;
}
.wrapper.style3 a {
  color: #778DA9;
}
.wrapper.style3 strong, .wrapper.style3 b {
  color: #fff;
}
.wrapper.style3 h1, .wrapper.style3 h2, .wrapper.style3 h3, .wrapper.style3 h4, .wrapper.style3 h5, .wrapper.style3 h6 {
  color: #fff;
}
.wrapper.style3 blockquote {
  border-left-color: transparent;
}
.wrapper.style3 code {
  background: rgba(255, 255, 255, 0.05);
  border-color: transparent;
}
.wrapper.style3 hr {
  border-bottom-color: transparent;
}
.wrapper.style3 header p {
  color: #fff;
}
.wrapper.style3 .feature {
  background-color: rgba(255, 255, 255, 0.05);
}
.wrapper.style3 label {
  color: #fff;
}
.wrapper.style3 input[type="text"], .wrapper.style3 input[type="password"], .wrapper.style3 input[type="email"], .wrapper.style3 select, .wrapper.style3 textarea {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: transparent;
  color: inherit;
}
.wrapper.style3 input[type="text"]:focus, .wrapper.style3 input[type="password"]:focus, .wrapper.style3 input[type="email"]:focus, .wrapper.style3 select:focus, .wrapper.style3 textarea:focus {
  border-color: #778DA9;
  box-shadow: 0 0 0 1px #778DA9;
}
.wrapper.style3 .select-wrapper:before {
  color: transparent;
}
.wrapper.style3 input[type="checkbox"] + label, .wrapper.style3 input[type="radio"] + label {
  color: rgba(255, 255, 255, 0.75);
}
.wrapper.style3 input[type="checkbox"] + label:before, .wrapper.style3 input[type="radio"] + label:before {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: transparent;
}
.wrapper.style3 input[type="checkbox"]:checked + label:before, .wrapper.style3 input[type="radio"]:checked + label:before {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: #778DA9;
  color: #fff;
}
.wrapper.style3 input[type="checkbox"]:focus + label:before, .wrapper.style3 input[type="radio"]:focus + label:before {
  border-color: #778DA9;
  box-shadow: 0 0 0 1px #778DA9;
}
.wrapper.style3 ::-webkit-input-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style3 :-moz-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style3 ::-moz-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style3 :-ms-input-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style3 .formerize-placeholder {
  color: #a5a9ad !important;
}
.wrapper.style3 form.alt input[type="text"], .wrapper.style3 form.alt input[type="password"], .wrapper.style3 form.alt input[type="email"], .wrapper.style3 form.alt select, .wrapper.style3 form.alt textarea {
  background-color: transparent;
}
.wrapper.style3 ul.alt > li {
  border-top-color: transparent;
}
.wrapper.style3 table tbody tr {
  border-color: transparent;
}
.wrapper.style3 table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.05);
}
.wrapper.style3 table th {
  color: #fff;
}
.wrapper.style3 table thead {
  border-bottom-color: transparent;
}
.wrapper.style3 table tfoot {
  border-top-color: transparent;
}
.wrapper.style3 table.alt tbody tr td {
  border-color: transparent;
}
.wrapper.style3 input[type="submit"], .wrapper.style3 input[type="reset"], .wrapper.style3 input[type="button"], .wrapper.style3 button, .wrapper.style3 .button {
  background-color: #fff;
  box-shadow: none;
  color: #1E2832 !important;
}
.wrapper.style3 input[type="submit"].icon:before, .wrapper.style3 input[type="reset"].icon:before, .wrapper.style3 input[type="button"].icon:before, .wrapper.style3 button.icon:before, .wrapper.style3 .button.icon:before {
  color: #a5a9ad;
}
.wrapper.style3 input[type="submit"]:hover, .wrapper.style3 input[type="reset"]:hover, .wrapper.style3 input[type="button"]:hover, .wrapper.style3 button:hover, .wrapper.style3 .button:hover {
  background-color: white;
}
.wrapper.style3 input[type="submit"]:active, .wrapper.style3 input[type="reset"]:active, .wrapper.style3 input[type="button"]:active, .wrapper.style3 button:active, .wrapper.style3 .button:active {
  background-color: #f2f2f2;
}
.wrapper.style3 input[type="submit"].special, .wrapper.style3 input[type="reset"].special, .wrapper.style3 input[type="button"].special, .wrapper.style3 button.special, .wrapper.style3 .button.special {
  background-color: #778DA9;
  box-shadow: none;
  color: #fff !important;
}
.wrapper.style3 input[type="submit"].special:hover, .wrapper.style3 input[type="reset"].special:hover, .wrapper.style3 input[type="button"].special:hover, .wrapper.style3 button.special:hover, .wrapper.style3 .button.special:hover {
  background-color: #415A77;
}
.wrapper.style3 input[type="submit"].special:active, .wrapper.style3 input[type="reset"].special:active, .wrapper.style3 input[type="button"].special:active, .wrapper.style3 button.special:active, .wrapper.style3 .button.special:active {
  background-color: #415A77;
}
.wrapper.style3 input[type="submit"].alt, .wrapper.style3 input[type="reset"].alt, .wrapper.style3 input[type="button"].alt, .wrapper.style3 button.alt, .wrapper.style3 .button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff !important;
}
.wrapper.style3 input[type="submit"].alt:hover, .wrapper.style3 input[type="reset"].alt:hover, .wrapper.style3 input[type="button"].alt:hover, .wrapper.style3 button.alt:hover, .wrapper.style3 .button.alt:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.wrapper.style3 input[type="submit"].alt:active, .wrapper.style3 input[type="reset"].alt:active, .wrapper.style3 input[type="button"].alt:active, .wrapper.style3 button.alt:active, .wrapper.style3 .button.alt:active {
  background-color: rgba(255, 255, 255, 0.1);
}
@media screen and (max-width: 1280px) {
  .wrapper {
    padding: 6em 0 4em 0;
  }
  .wrapper > .inner {
    width: 65em;
  }
}
@media screen and (max-width: 980px) {
  .wrapper {
    padding: 5em 3em 3em 3em;
  }
  .wrapper > .inner {
    width: 100%;
  }
}
@media screen and (max-width: 736px) {
  .wrapper {
    padding: 3em 2em 1em 2em;
  }
}
@media screen and (max-width: 480px) {
  .wrapper {
    padding: 2em 1em 0.1em 1em;
  }
}
/* Header */
#header {
  background-color: #fff;
}
/* Banner */
.home-banner {
  background-image: url("./assets/overlay.png"), url("./assets/light_home.jpg");
}
.webp .home-banner {
  background-image: url("./assets/overlay.png"), url("./assets/light_home.webp");
}
.about-banner {
  background-image: url("./assets/overlay.png"), url("./assets/Ipswich_Waterfront.jpg");
}
.webp .about-banner {
  background-image: url("./assets/overlay.png"), url("./assets/Ipswich_Waterfront.jpg");
}
.contact-banner {
  background-image: url("./assets/overlay.png"), url("./assets/phone.jpg");
}
.webp .contact-banner {
  background-image: url("./assets/overlay.png"), url("./assets/phone.jpg");
}
.banner {
  padding: 8em 0 6em 0;
  background-color: #0D1B2A;
  color: rgba(255, 255, 255, 0.75);
  background-attachment: fixed;
  background-size: cover;
  text-align: center;
}
.banner input, .banner select, .banner textarea {
  color: #fff;
}
.banner a {
  color: #778DA9;
}
.banner strong, .banner b {
  color: #fff;
}
.banner h1, .banner h2, .banner h3, .banner h4, .banner h5, .banner h6 {
  color: #fff;
}
.banner blockquote {
  border-left-color: transparent;
}
.banner code {
  background: rgba(255, 255, 255, 0.05);
  border-color: transparent;
}
.banner hr {
  border-bottom-color: transparent;
}
.banner input[type="submit"], .banner input[type="reset"], .banner input[type="button"], .banner button, .banner .button {
  background-color: #fff;
  box-shadow: none;
  color: #1E2832 !important;
}
.banner input[type="submit"].icon:before, .banner input[type="reset"].icon:before, .banner input[type="button"].icon:before, .banner button.icon:before, .banner .button.icon:before {
  color: #a5a9ad;
}
.banner input[type="submit"]:hover, .banner input[type="reset"]:hover, .banner input[type="button"]:hover, .banner button:hover, .banner .button:hover {
  background-color: white;
}
.banner input[type="submit"]:active, .banner input[type="reset"]:active, .banner input[type="button"]:active, .banner button:active, .banner .button:active {
  background-color: #f2f2f2;
}
.banner input[type="submit"].special, .banner input[type="reset"].special, .banner input[type="button"].special, .banner button.special, .banner .button.special {
  background-color: #415A77;
  box-shadow: none;
  color: #fff !important;
}
.banner input[type="submit"].special:hover, .banner input[type="reset"].special:hover, .banner input[type="button"].special:hover, .banner button.special:hover, .banner .button.special:hover {
  background-color: #415A77;
}
.banner input[type="submit"].special:active, .banner input[type="reset"].special:active, .banner input[type="button"].special:active, .banner button.special:active, .banner .button.special:active {
  background-color: #45ad97;
}
.banner input[type="submit"].alt, .banner input[type="reset"].alt, .banner input[type="button"].alt, .banner button.alt, .banner .button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff !important;
}
.banner input[type="submit"].alt:hover, .banner input[type="reset"].alt:hover, .banner input[type="button"].alt:hover, .banner button.alt:hover, .banner .button.alt:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.banner input[type="submit"].alt:active, .banner input[type="reset"].alt:active, .banner input[type="button"].alt:active, .banner button.alt:active, .banner .button.alt:active {
  background-color: rgba(255, 255, 255, 0.1);
}
.banner h2 {
  font-size: 2.5em;
  margin: 0 0 0.5em 0;
  padding: 0;
}
.banner p {
  color: #fff;
  font-size: 1.5em;
  margin-bottom: 1.75em;
  text-transform: uppercase;
}
@media screen and (max-width: 1680px) {
  .banner {
    padding: 11em 0 9em 0;
  }
}
@media screen and (max-width: 1280px) {
  .banner {
    padding: 9em 0 7em 0;
  }
  .banner h2 {
    font-size: 2em;
  }
}
@media screen and (max-width: 980px) {
  .banner {
    padding: 8em 2em 6em 2em;
  }
}
@media screen and (max-width: 736px) {
  .banner {
    padding: 5em 2em 3em 2em;
  }
  .banner h2 {
    font-size: 1.5em;
  }
  .banner p {
    font-size: 1em;
  }
}
@media screen and (max-width: 480px) {
  .banner {
    padding: 5em 2em 3em 2em;
  }
}
/* Footer */
#footer {
  background-color: #0D1B2A;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
}
#footer input, #footer select, #footer textarea {
  color: #fff;
}
#footer a {
  color: #415A77;
}
#footer strong, #footer b {
  color: #fff;
}
#footer h1, #footer h2, #footer h3, #footer h4, #footer h5, #footer h6 {
  color: #fff;
}
#footer blockquote {
  border-left-color: transparent;
}
#footer code {
  background: rgba(255, 255, 255, 0.05);
  border-color: transparent;
}
#footer hr {
  border-bottom-color: transparent;
}
#footer a {
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  color: #a5a9ad;
}
#footer a:active, #footer a:focus, #footer a:hover {
  color: #fff;
}
#footer .inner {
  padding: 6em 0 4em 0;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  width: 75%;
}
#footer ul.icons li {
  padding-right: 2.5em;
}
#footer ul.icons li:last-child {
  padding-right: 0;
}
#footer .copyright {
  color: #a5a9ad;
  font-size: 0.9em;
  margin: 0 0 2em 0;
  padding: 0;
  text-transform: uppercase;
}
#footer .copyright li {
  display: inline-block;
  list-style: none;
  margin: 0 0.1em;
}
@media screen and (max-width: 980px) {
  #footer .inner {
    padding: 5em 0 3em 0;
    width: 100%;
  }
}
@media screen and (max-width: 736px) {
  #footer .inner {
    padding: 3em 0 1em 0;
  }
  #footer .copyright li {
    display: block;
    margin: 0;
  }
}
@media screen and (max-width: 480px) {
  #footer .inner {
    padding: 2em 0 0.1em 0;
  }
}
/* Scrollbar */
body::-webkit-scrollbar {
  width: 16px;
  /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: #E0E1DD;
  /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: #1B263B;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #1B263B;
  /* creates padding around scroll thumb */
}



</style>
